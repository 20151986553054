<template>
  <div class="mine">
    <van-nav-bar
        title="Recipient Details"
        fixed
        placeholder
        left-arrow
        :border="false"
        @click-left="router.go(-1)"
    />
    <div class="main">
      <div class="title">Delivery Details</div>
      <div v-if="addressList.length" class="list">
        <div v-for="(item,index) in addressList" :key="index" class="group">
          <div class="flex">
            <div class="left">
              <img src="@/assets/imgs/tabbar3.png" alt="">
              <div class="name">{{ item.u_name }}</div>
            </div>
            <div class="edit" @click="router.push('/addAddress?edit=' + item.id)">Edit</div>
          </div>
          <div class="mobile">{{ item.mobile }}</div>
          <div class="mobile">{{ item.login_email }}</div>
        </div>
      </div>
      <div v-else class="noData">No data available</div>
      <div class="add" @click="router.push('/addAddress')">
        <img src="@/assets/imgs/icon_plus.png" alt="">
        <div class="text">Add New Address</div>
      </div>
    </div>
  </div>

  <TabBar/>
</template>

<script setup>
  import TabBar from "@/components/TabBar";
  import { useRouter } from 'vue-router';
  import { getUserAddress } from '@/request/api'
  import { ref } from 'vue'

  const router = useRouter()
  const addressList = ref([])
  getUserAddress({
    address: sessionStorage.getItem('address')
  }).then(res => {
    if(res.success) {
      addressList.value = res.data
    }
  })
</script>

<style lang="less" scoped>
.mine{


  .main{
    padding: 16px 24px;

    .title{
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 6px;
    }
    .list{
      margin-bottom: 32px;

      .group{
        font-size: 16px;
        padding: 15px 0;
        border-bottom: 1px solid #E8E7E7;

        .flex{
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 2px;

          .left{
            display: flex;
            align-items: center;

            img{
              width: 25px;
            }
            .name{
              color: #F8F8F8;
              margin-left: 16px;
            }
          }
          .edit{
            color: #06C8A1;
          }
        }
        .mobile{
          color: #697377;
          line-height: 24px;
          padding-left: 41px;
        }
      }
    }
    .noData{
      text-align: center;
      font-size: 13px;
      margin: 30px 0 60px 0;
    }
    .add{
      display: flex;
      align-items: center;
      justify-content: center;

      img{
        width: 22px;
      }
      .text{
        color: #F8F8F8;
        font-size: 16px;
        margin-left: 12px;
      }
    }
  }
}
</style>
